import './Feedback.css';
import React, { useRef, useState } from 'react';


function Feedback(props) {
const formRef = useRef();
const [loading, setLoading] = useState(false);

    function formSubmit() {
        setLoading(true);

        let seconds = 4;
        const redirect = () => {
            if (seconds === 0) {
                formRef.current.click();
            }
            
            document.querySelector('#submitted').innerText = `Submitted! Page will reload in ${seconds}`;
            seconds--;
        }
        setInterval(redirect, 1000);
     }

  return (
    <div className={`modal ${props.showModal ? "" : "hidden"}`}>
        <div className="feedback-form">
            <div className={`success ${loading ? "" : "hidden"}`}><h1 id="submitted">Submitted! Page will reload in 5</h1></div>
            <div className={`${loading ? "hidden" : ""}`}>

            <button className="close" onClick={props.closeModal}>x</button>
            <h1>Feedback form</h1>
            <form action={"https://riku.miso.town/submit?user_id=5&label=goodrickgame"} method="post">
                <div className="field">
                    
                    <label htmlFor="name">Name</label><br />
                    <input type="text" name="name" id="name" required placeholder="Name" />
                </div>

                <div className="field">
                    <label htmlFor="email">Email</label><br />
                    <input type="email" name="email" id="email" placeholder="Email (optional)" />
                </div>

                <div className="field">
                    <label htmlFor="message">Message</label><br />
                    <textarea name="message" id="message" required placeholder="Message"></textarea>
                </div>

                <input type="submit" value="Submit" className="submit-button" ref={formRef} style={{display: "none"}} />
                <input type="hidden" name="redirect" value="https://goodrick.julipode.net" />
            </form>
            <button onClick={formSubmit}>Submit haha</button>

            </div>

        </div>
    </div>
  );
}

export default Feedback
