import AudioReactRecorder, { RecordState }from 'audio-react-recorder'
import { useState, useRef } from 'react';
import { storage } from './firebase';
import { ref, uploadBytes } from 'firebase/storage'
import {v4} from 'uuid';
import "./Recorder.css";

function Recorder(props) {
const [recordState, setRecordState] = useState(RecordState.STOP);
const [audio, setAudio] = useState();
const myRef = useRef();

const uploadRecording = () => {
  const recordingRef = ref(storage, `Recordings/${v4()}`);
  
  getFileBlob(audio.url, blob =>{
    uploadBytes(recordingRef, blob).then(() => {
       console.log('Uploaded a blob or file!');
    })
  })
}

const getFileBlob = function (url, cb) {
  var xhr = new XMLHttpRequest();
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.addEventListener('load', function() {
    cb(xhr.response);
  });
  xhr.send();
};

  const start = () => {
    setRecordState(RecordState.START)
    myRef.current.play();
  }

  const stop = () => {
   setRecordState(RecordState.STOP)
   myRef.current.pause();
   myRef.current.currentTime = 0;
  }

  const recordButton = () => {
    if(recordState === RecordState.STOP){
      start()
    } else if(recordState === RecordState.START){
      stop()
    }
  }

  //audioData contains blob and blobUrl
  const onStop = (audioData) => {
    setAudio(audioData);
  }

  return (
    <div>
        <div className="record-container">
          <h3>{ recordState === RecordState.START ? "Recording" : "Record"}</h3>

          <div className={`record-button ${recordState === RecordState.START ? "active" : ""}`} >
            <button className={`record ${recordState === RecordState.START ? "active" : ""}`} onClick={recordButton}></button>
          </div>
          
          <audio hidden={audio && recordState !== RecordState.START ? false : true} controls src={audio?.url}></audio>
        </div>    
        <button style={{visibility: audio && recordState !== RecordState.START ? "visible" : "hidden"}} className="upload-button" onClick={uploadRecording}>Upload Recording</button>

        <AudioReactRecorder canvasWidth={200} canvasHeight={100} state={recordState} onStop={onStop} />
        <audio ref={myRef} hidden src={`${process.env.PUBLIC_URL}/Audio/${props.vamp}`}></audio>
    </div>
  );
}

export default Recorder;
