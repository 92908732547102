// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getStorage} from "firebase/storage"
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC_bne4EMa9As3ODMV6shoy9Kd7aZMCE3c",
  authDomain: "goodrickgame.firebaseapp.com",
  projectId: "goodrickgame",
  storageBucket: "goodrickgame.appspot.com",
  messagingSenderId: "841823777172",
  appId: "1:841823777172:web:40b8e01c36cb23b4cfdf81"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);