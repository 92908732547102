function cyrb128(str) {
    let h1 = 1779033703, h2 = 3144134277,
        h3 = 1013904242, h4 = 2773480762;
    for (let i = 0, k; i < str.length; i++) {
        k = str.charCodeAt(i);
        h1 = h2 ^ Math.imul(h1 ^ k, 597399067);
        h2 = h3 ^ Math.imul(h2 ^ k, 2869860233);
        h3 = h4 ^ Math.imul(h3 ^ k, 951274213);
        h4 = h1 ^ Math.imul(h4 ^ k, 2716044179);
    }
    h1 = Math.imul(h3 ^ (h1 >>> 18), 597399067);
    h2 = Math.imul(h4 ^ (h2 >>> 22), 2869860233);
    h3 = Math.imul(h1 ^ (h3 >>> 17), 951274213);
    h4 = Math.imul(h2 ^ (h4 >>> 19), 2716044179);
    return [(h1^h2^h3^h4)>>>0, (h2^h1)>>>0, (h3^h1)>>>0, (h4^h1)>>>0];
}

function mulberry32(a) {
    return function() {
      var t = a += 0x6D2B79F5;
      t = Math.imul(t ^ t >>> 15, t | 1);
      t ^= t + Math.imul(t ^ t >>> 7, t | 61);
      return ((t ^ t >>> 14) >>> 0) / 4294967296;
    }
}

function getRandomByDate(){
    const date = new Date();
    const seeds = cyrb128(`${date.getMonth()}/${date.getDate()}/${date.getFullYear()}`);
    return (mulberry32(seeds[0])());
}

export function generate() {
    const stringPairs = ["EB", "BG", "GD", "DA", "AE"];
    const modes = [{scale: "C Ionian", vamp: "Ionian.png", audio: "B_Locrian.wav"},
                    {scale: "D Dorian", vamp: "Dorian.png", audio: "B_Locrian.wav"}, {scale: "E Phrygian", vamp: "Phrygian.png", audio: "B_Locrian.wav"}, 
                    {scale: "F Lydian", vamp: "Lydian.png", audio: "B_Locrian.wav"}, { scale: "G Mixolydian", vamp: "Mixolydian.png", audio: "B_Locrian.wav"},
                    { scale: "A Aeolian", vamp: "Aeolian.png", audio: "B_Locrian.wav"}, { scale: "B Locrian", vamp: "Locrian.png", audio: "B_Locrian.wav"}];
    const stringResult = stringPairs[Math.floor(getRandomByDate()*stringPairs.length)];
    const modeResult = modes[Math.floor(getRandomByDate()*modes.length)];
    const resultText = `${stringResult[0]} and ${stringResult[1]} strings in ${modeResult.scale}`;
   
    return {audio: modeResult.audio, musicImage: modeResult.vamp, resultText}
  }