import './App.css';
import React, { useEffect, useState } from 'react';
import { generate } from "./util"
import { storage } from './firebase';
import { listAll, ref, getDownloadURL } from 'firebase/storage'

import Recorder from './Recorder';
import Feedback from './Feedback';

function App() {
  const {audio, musicImage, resultText} = generate();
  const [recordingList, setRecordingList] = useState([]);
  const recordingListRef = ref(storage, 'Recordings/');

  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  useEffect(() => {
    listAll(recordingListRef).then(response => {
      response.items.forEach(item => {
        getDownloadURL(item).then(url => {
          setRecordingList(prev => [...prev, url]);
        })
      })
    })
  }, []);

  return (
    <div className="wrapper">
      <div className="left-panel">
<div className="left-panel-content">
<h1>FAQ</h1>
        <h3>What is this?</h3>
        <p>In <i>The Advancing Guitarist</i>, Mick Goodrick outlines a practice of modal improvisation over a drone using only two adjacent strings. 
        This is a tool that picks two strings and a mode and provides you with the tools to establish a daily practice and share your results. All modes have no sharps or flats.</p>
        <h3>Rules:</h3>
        <ul>
          <li>Only use the two <i>adjacent</i> strings specified</li>
          <li><b>Don't spam uploads</b>, try to stick to one upload a day (a few are fine if they are different) or I will have to update the code to force that</li>
          <li><b>Don't upload/record anything irrelevant</b>, it will be taken down</li>
        </ul>
        <h3>Suggestions:</h3>
        <ul>
          <li><b>Use an audio interface</b> for better quality recordings when sharing</li>
          <li><b>Don't be shy</b>, upload your improv no matter how you feel about it, build community!</li>
          <li><b>Explore</b>, have fun, make mistakes!</li>
        </ul>
        <h3>Feedback:</h3>
        <p>If you'd like to see a certain feature, or encounter a bug, let me know with <a className="feedback-link" onClick={()=>setShowFeedbackModal(true)}>this form</a></p>
        <h3>Can I support you for your work?</h3>
        <p>Yes! Click the link below to "buy me a coffee". Or hire me to work on a tool or personal website, teach guitar lessons, or record guitar</p>
        <a href='https://ko-fi.com/U6U5CH838' target='_blank' style={{ display: "flex", justifyContent:"center"}}>
          <img height='36' style={{ border:"0px", height:"36px", marginBottom: "30px" }} src='https://cdn.ko-fi.com/cdn/kofi1.png?v=3' border='0' alt='Buy Me a Coffee at ko-fi.com' />
          </a>
</div>


      </div>
      <main>
        <div className="announcement">
            <h1>Today's Improv is</h1>
            <div id="result">{resultText}</div>
        </div>
        <div className="vamp" id="vamp">
          <img src={`${process.env.PUBLIC_URL}/Vamps/${musicImage}`} />
        </div>
        <div id="synth">
            <Recorder vamp={audio} />
        </div>
    </main>
    <div className="recording-section">
          <h1>Today's Uploads</h1>
          <div className="recordings">
            {recordingList.map(url => {
              return (<><audio  src={url} className="recording" controls></audio><br /></>)
            })}
          </div>
        </div>
        <Feedback closeModal={()=>setShowFeedbackModal(false)} showModal={showFeedbackModal}/>
    </div>
  );
}

export default App